import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import ImageTag from "gatsby-image";
import testimonialImage from './Andrew-Wear.jpg';

const supportLinks = [
  {
    name: 'Trusted Partner',
    description:
      <span>
        WAIVPAY delivered to City of Melbourne a multi-million cashback
        program on time and on budget. Our turnkey system empowered the city to
        deliver great benefits to its dining commmunity quickly, economically
        and reliably, and created great value for participating restaurants.
      </span>
  },
  {
    name: 'Shopper Engagement',
    description:
      <span>
        Exceeding all projections, the city&rsquo;s cashback program delivered
        cash rebates to diners quickly, easily and hassle-free. Diners loved
        the magic of taking a photo of a receipt and receiving
        cash back almost instantenously, and WAIVPAY customer service was
        always there to help.
      </span>
  },
  {
    name: 'Technology Leadership',
    description:
      <span>
        Scability, security and reliabillty built throughout the system,
        WAIVPAY&rsquo;s technology cost-effectively delivered the city&rsquo;s
        innovative cashback program. Our automated cashbacks scanned
        diners&rsquo; receipts, instantly confirmed validity, and then
        forwarded payment. Fraud detection was automated and rigorous.
      </span>
  },
];

const CityOfMelbourne = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(name: { eq: "com-background" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="bg-gray-100">
      {/* Header */}
      <div className="relative pb-32 bg-gray-800">
        <div className="absolute inset-0">
          <ImageTag Tag="div"
            fluid={image.sharp.fluid}
            className="h-full w-full object-cover opacity-90"
            alt="Interior view of a restaurant with groups of diners at many tables"
          />
          <div className="absolute inset-0 bg-highlight mix-blend-multiply" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
            <span className="block text-white">City Of Melbourne</span>
            <span className="block text-highlight-light">Partners with WAIVPAY</span>
          </h1>
          <h2 className="mt-6 max-w-lg mx-auto text-center text-3xl text-gray-50 sm:max-w-4xl">
            Australia&rsquo;s largest cashback program drives Melbourne&rsquo;s recovery
          </h2>
          <p className="mt-6 max-w-lg mx-auto text-center text-xl text-gray-50 sm:max-w-4xl">
            WAIVPAY successfully delivers the city&rsquo;s $40&nbsp;million dining
            initiative to bring shoppers back to Melbourne&rsquo;s hospitality sector.
            Highly succcessful, shoppers received over $8&nbsp;million in cashback
            incentives when they dined in the city&rsquo;s restaurants, cafes and bars.
          </p>

          {/* Stats */}
          <dl className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-50">Total Rebates</dt>
              <dd className="order-1 text-5xl font-extrabold text-white">270,154</dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-50">Total Cashback</dt>
              <dd className="order-1 text-5xl font-extrabold text-white">$8.03M</dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-50">Average Cashback</dt>
              <dd className="order-1 text-5xl font-extrabold text-white">$29.74</dd>
            </div>
          </dl>


          {/* Midweek Melbourne */}
          <div className="bg-highlight bg-opacity-50 rounded-2xl">
            <div className="mt-20 max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <h2 className="mx-auto text-center text-4xl text-gray-50">
                  WAIVPAY to deliver &ldquo;Midweek Melbourne Money&rdquo;
                </h2>

                <div className="mt-8 px-0 sm:px-6 grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-y-0 lg:gap-x-8">
                  {/* Blurb */}
                  <p className="text-left text-lg sm:text-2xl text-gray-50 leading-relaxed opacity-90">
                    After the huge success of the first two programs, City&nbsp;of&nbsp;Melbourne and
                    WAIVPAY are again partnering to deliver the third &ldquo;Midweek Melbourne Money&rdquo;
                    to drive people back into the city. Shoppers receive a 25%
                    cashback on their dining expense when eating in the City of Melbourne.
                  </p>

                  {/* Testimonial */}
                  <blockquote className="md:flex-grow md:flex md:flex-col">
                    <div className="relative md:flex-grow">
                      <svg
                        className="absolute top-0 sm:top-4 left-0 sm:-left-4 transform -translate-x-0 -translate-y-7 h-8 w-8 text-highlight opacity-50"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className="relative text-lg font-medium text-gray-50 opacity-90 italic text-left">
                        It&rsquo;s been an absolute pleasure working with the WAIVPAY team to deliver the
                        Melbourne Money and now Midweek Melbourne Money programs. WAIVPAY is a highly
                        professional organisation that continues to work collaboratively with the City of
                        Melbourne to deliver this most important economic recovery program.
                      </p>
                    </div>
                    <footer className="mt-8">
                      <div className="flex items-start">
                        <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                          <img
                            className="h-12 w-12 rounded-full"
                            src={testimonialImage}
                            alt="Andrew Wear"
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-base font-medium text-gray-50">Andrew Wear, Director</div>
                          <div className="text-base font-medium text-gray-50">Economic Development and International, City of Melbourne</div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
            </div>
          </div>
        </div>
      </div>

      {/* Overlapping cards */}
      <section className="-mt-48 max-w-7xl mx-auto relative z-10 pb-16 px-4 sm:px-6 lg:px-8" aria-labelledby="contact-heading">
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
              <div className="flex-1 relative py-8 px-6 md:px-8">
                <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
                <p className="mt-4 text-base text-gray-500">{link.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

export default CityOfMelbourne;
